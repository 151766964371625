import React, {useContext, useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ModalRegister from '../components/ModalRegister';
import {Container, Row, Col} from 'react-bootstrap';

import {GlobalDispatchContext, GlobalStateContext} from "../context/GlobalContextProvider";
import ModalMessi from "./ModalMessi"

const Layout = ({children,summer}) => {

    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    const [firstTimeModalRegister, setfirstTimeModalRegister] = useState(false);
    const [showModalRegister, setShowModalRegister] = useState(false);
    const [showModalRegisterOld, setShowModalRegisterOld] = useState(false);

    useEffect(() => {
        showModalFirstTime()
    },[])

    const showModalFirstTime = () => {
        if(state.showModalRegister) {
            setfirstTimeModalRegister(true)
            setShowModalRegister(true)
            dispatch({ type: "CHANGE_MODALREGISTER" })
        }
    }

    const handlerModalRegister = () => {
        setfirstTimeModalRegister(false)
        setShowModalRegister(!showModalRegister)
    }
    const handlerModalRegisterOld = () => {
        setShowModalRegisterOld(!showModalRegisterOld)
    }

    return (
        <Container fluid className="m-0 p-0">
            <Row fluid className="m-0 p-0">
                <Col fluid className="m-0 p-0">
                    <Header handlerModalRegister={handlerModalRegister} handlerModalRegisterOld={handlerModalRegisterOld} />
                        {/* only first time */}
                        {/* {firstTimeModalRegister || showModalRegister ? <ModalMessi handlerModalRegister={handlerModalRegister} show={showModalRegister}/> : null} */}
                        {showModalRegisterOld && <ModalRegister handlerModalRegister={handlerModalRegisterOld} show={showModalRegisterOld}/>}
                        {children}
                    <Footer summer={summer}/>
                </Col>
            </Row>
        </Container>
    )
    
}

export default Layout;