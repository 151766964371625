import React, {Fragment, useState, useEffect} from 'react';
import {graphql, useStaticQuery} from 'gatsby'
import {Row, Col, Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import styles from '../scss/components/_ModalRegister.module.scss';
import Form from 'react-bootstrap/Form';
import Img from 'gatsby-image';
import {IsMobileDevice,emailRegexp} from '../utils/index';
import ModalConditions from './ModalConditions.js';
import ModalPrivacy from './ModalPrivacy.js';

const ModalRegister = (props) => {

    const [isMobile, setIsMobile] = useState(null);
    const [modalCondition, setmodalCondition] = useState(null);
    const [modalPrivacy, setmodalPrivacy] = useState(null);
    const [sendForm, setSendForm] = useState(false);
    const [formError, setFormError] = useState(false);
    const [age, setAge] = useState('');
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [legal, setLegal] = useState(false);

    useEffect(() => {
        setIsMobile(IsMobileDevice());
        activeAnimations(props.show)
    },[])
    
    const activeAnimations = (state) => {
        if(state){
            //slide modal
            document.querySelector('#modal').classList.remove("slideOutTop")
            document.querySelector('#modal').classList.add("slideInTop") 
            //fade gradient
            document.querySelector('#gradientRegister').classList.remove("fade-out") 
            document.querySelector('#gradientRegister').classList.add("fade-in") 
            if(isMobile){
                //scroll off
                document.body.style.overflowY = 'hidden';
                document.querySelector('#gradientRegister').style.display = 'block';
            }
        }else{
            //slide modal
            document.querySelector('#modal').classList.remove("slideInTop")
            document.querySelector('#modal').classList.add("slideOutTop") 
            //fade gradient
            document.querySelector('#gradientRegister').classList.remove("fade-in") 
            document.querySelector('#gradientRegister').classList.add("fade-out") 
            if(isMobile){
                //scroll on
                document.body.style.overflowY = 'scroll';
                document.querySelector('#gradientRegister').style.display = 'none';
            }
        }
    }

    const handlerCloseButton = (e) => {
        activeAnimations(false);
        setTimeout(() => {
            props.handlerModalRegister(e);
        },1000)
    }

    const onChange = name => (event) => {
        if (name === 'name'){
            setName(event.target.value);
        }else if (name === 'mail'){
            setMail(event.target.value);
        }else if (name === 'age'){
            setAge(event.target.value);
        }else if (name === 'legal'){
            setLegal(!legal);
        }
    };

    const validateMail = (data) => {
        return emailRegexp.test(data);
    }

    const submitForm = (e) => {
        e.preventDefault();
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Register",
            event_action: "Enviar",
            event_label: "",
          })

        if(validateMail(mail) && name && age !== ''  && legal) {
            let form = {
                name : name,
                email : mail,
                age
            }
            fetch('https://wabudweiser-prd.azurewebsites.net/miniform', {
                method: 'POST',
                body: JSON.stringify(form),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
              .then(response => {
                  if(response.ok) {
                      setFormError(false);
                      setSendForm(true);
                      window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Register",
                        event_action: "Success",
                        event_label: "",
                      })
                  } else {
                      console.error("Error guardando los datos. \n", response);
                      window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Register",
                        event_action: "Error",
                        event_label: "Error guardando los datos",
                      })
                  }
              });

        }else {
            setFormError(true)
            setSendForm(false);
            window.dataLayer.push({
                event: "GAEvent",
                event_category: "Register",
                event_action: "Error",
                event_label: "Falta completar datos",
              })
        }
    }

    const data = useStaticQuery(graphql`
        query backgroundForm {
            file(base: {eq: "backgroundForm.png"}) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }`
    );

    return (
        <Fragment>
            <div className={styles.GradientRegister} id="gradientRegister"></div>
            <div className={styles.ContainerModalRegister} id="modal">
                <Modal.Dialog className={sendForm ? styles.ModalDialogSuccess : styles.ModalDialog}>
                    <Img fluid={data.file.childImageSharp.fluid} loading="eager" className={styles.backgroundImg} />
                    <div className={styles.Gradient}>
                        <Modal.Header className={styles.Header}>
                            <Button onClick={e => {handlerCloseButton(e)}} className={styles.CloseButton}>×</Button>
                        </Modal.Header>

                        {sendForm ?
                            <Modal.Body className={styles.Body}>
                                <Row className="d-flex justify-content-center w-100">
                                    <Col xs={12}>
                                        <p className={styles.BodyTextSendForm}>Gracias por Suscribirte!</p>
                                        <p className={styles.BodyTextSendForm}>Ya estás participando por un sorteo para ganarte premios increíbles.</p>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        : 
                            <Modal.Body className={styles.Body}>
                                <Row>
                                    <Col xs={12}>
                                        <p className={styles.BodyText}>Completá los datos y participá por un sorteo para ganarte premios increíbles como Tickets, Descuentos, Six Packs, el Outfit de Bud y Accesorios. Good luck!</p>
                                    </Col>
                                    <Col xs={12}>
                                        <Form className={styles.Form}>
                                            {/* name */}
                                            <Row>
                                                <Col>
                                                    <Form.Group className={styles.LabelForm}>
                                                        <Form.Control type="text" onChange={onChange('name')} placeholder="Nombre y Apellido" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {/* age and email desktop*/}
                                            <Row >
                                                {isMobile ? null :
                                                    <Col md={4} xs={5} id="age">
                                                        <Form.Group className={styles.LabelForm}>
                                                            <Form.Control type="number" onChange={onChange('age')} placeholder="Edad" />
                                                        </Form.Group>
                                                    </Col>
                                                }
                                                <Col md={8} xs={12} id="email">
                                                    <Form.Group className={styles.LabelForm} >
                                                        <Form.Control type="email" onChange={onChange('mail')} placeholder="Mail" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            
                                            {/* submit desktop */}
                                            {isMobile ? null : 
                                                <Row className="d-flex justify-content-center">
                                                    <Col xs={'auto'}>
                                                        <Button className={styles.SubmitButton} onClick={e => submitForm(e)} type="submit">
                                                            Enviar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }

                                            {/* edad and submit mobile*/}
                                            {isMobile ? 
                                                <Row className="d-flex justify-content-between">
                                                    <Col xs={5}>
                                                        <Form.Group className={styles.LabelForm}>
                                                            <Form.Control type="number"  onChange={onChange('age')} placeholder="Edad" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Button className={styles.SubmitButton} onClick={e => submitForm(e)} type="submit">
                                                            Enviar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            : null}
                                            
                                            {/* checkbox */}
                                            <Row className="d-flex justify-content-center mt-3" id='legal'>
                                                <Col xs={'auto'} className="p-0">
                                                    <label>
                                                        <input type="checkbox"onChange={e => setLegal(!legal)}/> 
                                                        <span></span>
                                                    </label>
                                                </Col>
                                                <Col xs={10} className="pl-2 pr-0">
                                                        <span className={styles.spanCheckbox}>He leído y acepto bases y condiciones, políticas de privacidad y activaciones de marketing.</span>
                                                
                                                </Col>
                                            </Row>
                                            
                                            {/* error */}
                                            {formError &&
                                                <Row className="d-flex justify-content-center">
                                                    <Col xs={'auto'}>
                                                        <h3 className={styles.FormError}>Debe completar todos los campos</h3>
                                                    </Col>
                                                </Row>
                                            }
                                        </Form>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        }

                        <Modal.Footer className={styles.Footer}>
                            <Row>
                                <Col xs={'auto'} className="text-center">
                                    <p className={styles.FooterTextUp}>Beber con moderación. Prohibida su venta a menores de 18 años.</p>
                                    <p className={styles.FooterTextDown}>Al enviar este formulario usted acepta estar sujeto a los <Button href="#modalConditions" className={styles.linkFooter} onClick={e => setmodalCondition(!modalCondition)}>terminos y condiciones</Button> y las <Button href="#modalPrivacy" className={styles.linkFooter} onClick={e => setmodalPrivacy(!modalPrivacy)}>politicas de privacidad</Button></p>
                                </Col>
                            </Row>
                    </Modal.Footer>
                    </div>
                </Modal.Dialog>
            </div>
            <ModalConditions show={modalCondition}/>
            <ModalPrivacy show={modalPrivacy}/>
        </Fragment>
    );
    
}

export default ModalRegister